import React from "react";
import { Container } from "reactstrap";

const CountryPage = () => {
  return (
    <Container className="pages-padding-top">
      <h1>Page under construction...</h1>
    </Container>
  );
};

export default CountryPage;
